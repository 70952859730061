import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zsevic.github.io/zsevic.github.io/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Electron Starter" mdxType="SEO" />
    <h1 {...{
      "id": "electron-starter-the-foundation-for-your-next-desktop-application"
    }}>{`Electron Starter: The Foundation for Your Next Desktop Application`}</h1>
    <p>{`Electron boilerplate lays the groundwork, simplifying your path to a reliable desktop application.`}</p>
    <h2 {...{
      "id": "key-features"
    }}>{`Key Features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`TypeScript with Next.js and Tailwind CSS for Renderer: Smooth UI development and styling in one setup.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`TypeScript for Electron Main Process: Cleaner code, optimized for Electron's main process.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`GitHub Action Workflows: Efficient CI tasks with simplified GitHub releases.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Integrated Logger: Built-in tool for easy debugging and monitoring.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "tech-stack"
    }}>{`Tech Stack`}</h2>
    <ul>
      <li parentName="ul">{`Node.js, TypeScript, Electron, Next.js, React, Tailwind CSS`}</li>
    </ul>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <p>{`To get started with the Electron boilerplate,
you will need to `}<a parentName="p" {...{
        "href": "https://github.com/sponsors/zsevic"
      }}>{`become a GitHub sponsor`}</a>{`.
Once you are a `}<a parentName="p" {...{
        "href": "https://github.com/sponsors/zsevic"
      }}>{`sponsor`}</a>{`,
you will automatically gain access to the `}<a parentName="p" {...{
        "href": "https://github.com/delimitertech/electron-starter"
      }}>{`repository`}</a>{`.`}</p>
    <p>{`From there, you can clone the `}<a parentName="p" {...{
        "href": "https://github.com/delimitertech/electron-starter"
      }}>{`repository`}</a>{` and start using the boilerplate
for your projects.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      